<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="card in variableCards" :key="card.title" :cols="card.flex">
        <v-card :color="'grey lighten-4'" :disabled="!card.route" hover :to="card.route ? card.route : ''">
          <v-img :src="card.src" class="white--text align-end" height="100px">
            <!--            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"-->
          </v-img>
          <v-card-title v-text="card.title"></v-card-title>

          <v-card-subtitle v-text="card.subTitle"></v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-for="card in staticCards.slice(0, 2)" :key="card.title" :cols="card.flex">
        <v-card :disabled="!card.route" hover :to="card.route ? card.route : ''">
          <v-img :src="card.src" class="white--text align-end" height="100px">
            <!--            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"-->
          </v-img>
          <v-card-title v-text="card.title"></v-card-title>

          <v-card-subtitle v-text="card.subTitle"></v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col v-for="card in staticCards.slice(2, 4)" :key="card.title" :cols="card.flex">
        <v-card :disabled="!card.route" hover :to="card.route ? card.route : ''">
          <v-img :src="card.src" class="white--text align-end" height="100px">
            <!--            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"-->
          </v-img>
          <v-card-title v-text="card.title"></v-card-title>

          <v-card-subtitle v-text="card.subTitle"></v-card-subtitle>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="showOpenDrawer" dense>
      <v-col>
        <v-card hover @click="openDrawer()">
          <v-img class="white--text align-end"> </v-img>
          <v-card-title>{{ $vuetify.lang.t("$vuetify.openCashDrawer") }}</v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getErrorMessage } from "@/_helpers";
import Vue from "vue";

export default {
  name: "Início",
  data: () => ({
    variableCards: [],
    staticCards: [],
  }),
  computed: {
    showOpenDrawer() {
      let deviceProps = JSON.parse(localStorage.getItem("deviceProps"));
      let device = "drawer";
      let prop = "status";
      let status;

      if (deviceProps !== null && device in deviceProps) {
        if (deviceProps[device][prop] !== null && prop in deviceProps[device]) {
          status = deviceProps[device][prop];
        } else {
          status = 0;
        }
      } else {
        status = 0;
      }

      return status;
    },
  },
  methods: {
    defineStaticCards() {
      this.staticCards = [
        {
          //"Vendas Suspensas",
          title: this.$vuetify.lang.t("$vuetify.suspendedSales"),
          subTitle: this.$vuetify.lang.t("$vuetify.consultSuspended"),
          src: "require('@/assets/no-photo.jpg')",
          flex: 6,
          route: { name: "sale-saved-carts" },
        },
        {
          // "Consulta de Vendas",
          title: this.$vuetify.lang.t("$vuetify.consultSales"),
          subTitle: this.$vuetify.lang.t("$vuetify.consultSalesDescription"),
          src: "require('@/assets/no-photo.jpg')",
          flex: 6,
          route: { name: "sales-history" },
        },
        {
          // "Sessões",
          title: this.$vuetify.lang.t("$vuetify.sessions"),
          subTitle: this.$vuetify.lang.t("$vuetify.sessionsProcedure"),
          src: "require('@/assets/no-photo.jpg')",
          flex: 6,
          route: { name: "salesmen-sessions" },
        },
        {
          // "Caixas",
          title: this.$vuetify.lang.t("$vuetify.cashDrawers"),
          subTitle: this.$vuetify.lang.t("$vuetify.cashDrawersProcedure"),
          src: "require('@/assets/no-photo.jpg')",
          flex: 6,
          route: { name: "cash-drawers-management" },
        },
      ];
    },
    getDisplayCards() {
      // let salesFlexValue = this.cards[0]["flex"];
      this.finalCards = [];

      //After the Default Sale's section, there might be other Sale's section depending on how many Zones
      // are active for the Store.
      //Therefore, to the Cards array it is necessary to add all the Zones, which should be displayed
      // right after the Default Sale's section.
      // 1 - getting the active Zones
      // 2 - adding the Zone to the Cards array
      // (the definition of the Route, includes the identification of the Sale's Zone code)
      this.$store.getters["stores/getAllZones"].then(
        (result) => {
          if (result.length > 1) {
            for (var i = 0; i < result.length; i++) {
              this.variableCards.push({
                title: this.$vuetify.lang.t("$vuetify.sales") + " " + result[i].description,
                subTitle: this.$vuetify.lang.t("$vuetify.salesDescription"),
                src: "require('@/assets/no-photo.jpg')",
                flex: 3,
                //@modified ana.castro 2022.12.05 SAFO-47
                // route: {
                //   name: result[i].salesPriceRange === 1 ? "catalog" : "catalog" + result[i].salesPriceRange,
                //   params: { validateCashDrawer: true, zone: result[i].code, salePriceRange: result[i].salesPriceRange },
                // },
                route: { name: "catalog", params: { zone: result[i].code, validateCashDrawer: true } },
              });
            }
          } else {
            this.variableCards = [
              {
                title: this.$vuetify.lang.t("$vuetify.sales"),
                subTitle: this.$vuetify.lang.t("$vuetify.salesDescription"),
                src: "require('@/assets/no-photo.jpg')",
                flex: 12,
                //@modified ana.castro 2021-08-12 2128
                // route: { name: "catalog", params: { validateCashDrawer: true, salePriceRange: 1 } },
                //@modified ana.castro 2022.12.05 SAFO-47
                route: { name: "catalog", params: { zone: 0, validateCashDrawer: true } },
              },
            ];
          }
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorStoreZone"));
        }
      );
    },
    openDrawer() {
      const urlStatus = "http://127.0.0.1:9090/opendrawer&device=2";
      Vue.axios.get(urlStatus);
    },
  },

  mounted() {
    this.defineStaticCards();
    this.getDisplayCards();
  },
};
</script>
